import React from "react";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <section className={styles.heroSection}>
      {/*   <div className={styles.imageContainer}>
        <img src={aliens} alt="Aliens" className={styles.image} />
  </div> */}
      <h2 className={styles.heading}>TOKEN PROMOTED NOW: UNS</h2>
      <div className={styles.whiteList}>
        <span className={styles.circle} />
        <span>Sales is Live</span>
      </div>
      <p className={styles.text}>
        UNS is focused on building partnerships with various organizations specifically in essential sectors: Agriculture through Hydroponic Farming, Education, Solar Energy, Healthcare and Crypto Exchange.
      </p>
    </section>
  );
};

export default HeroSection;
