import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react"
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import { useEffect } from "react";

import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { bsc, bscTestnet, goerli, sepolia } from "wagmi/chains";

//

const customChain = {
  id: 95,
  name: 'bsc testnet',
  network: 'bnb',
  nativeCurrency: {
    decimals: 18,
    name: 'BSC Testnet',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: { http: ['https://data-seed-prebsc-1-s3.binance.org:8545/'] },
  },
  blockExplorers: {
    etherscan: { name: 'HeptonScan', url: 'https://testnet.bscscan.com/' },
    default: { name: 'HeptonScan', url: 'https://testnet.bscscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11907934,
    },
  },
}

const chains = [bsc];

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "d3873ed18b4ac26cf6f5dffdb2fe9cbd" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    projectId: "d3873ed18b4ac26cf6f5dffdb2fe9cbd",
    version: "1", // or "2"
    appName: "web3Modal",
    chains,
  }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);


function App() {

  const [totalMinted, settotalMinted] = useState(0);

  useEffect(() => {

  }, [totalMinted]);

  return (
    <>
      <BrowserRouter>


        <WagmiConfig client={wagmiClient}>
          <Web3Modal
            projectId="d3873ed18b4ac26cf6f5dffdb2fe9cbd"
            ethereumClient={ethereumClient}
          />

          <Header />
          <Routes>
            <Route path="/:id" element={<Home

            />}></Route>
            <Route path="/" element={<Home

            />}></Route>

          </Routes>
        </WagmiConfig>
      </BrowserRouter>
    </>
  );
}

export default App;
